import React from 'react'
import "./Header.scss"

function Header() {
    return (
        <div className="header">
            <div className="space"></div>
            <div className="wrapName">
                <div className="name">Francisco Romo</div>
                <div className="position">Administrador General</div>
            </div>
        </div>
    )
}

export default Header
