import React from 'react';
import './LogoHome.scss';

function LogoHome() {
    return (
        <div className="logoHome"></div>
    )
}

export default LogoHome
